@import "../sass/Customization.scss";
// Font and Bg Colors
// $PrimaryColor: #0c4a69 ;
// $SecondaryColor: #e85630 ;
$HeadingColor: #2a4350;

// Font Families
$PrimaryFontFamily: Primary-Font;
$PrimaryBold: Primary-Bold;
$PrimaryExtraBold: Primary-ExtraBold;
$SecondaryFontFamily: Seondary-Font;

// Font Sizes Desktop
$BannerFontSize: 48px ;
$BannerSubFontSize: 30px ;

$HeadingFontSize: 35px ;
$SubHeadingFontSize: 25px ;
$ParaFontSize: 18px ;
$FontQuote: 88px ; 

// Font Sizes Tablet
$TabletBannerFontSize: 40px ;
$TabletBannerSubFontSize: 25px ;

// Font Sizes Mobile
$MobileBannerFontSize: 25px ;
$MobileBannerSubFontSize: 20px ;

$HeadingFontSizeMobile: 25px ;
$SubHeadingFontSizeMobile: 20px ;
$ParaFontSizeMobile: 16px ; 

// Horizontal Line Customization
$BorderHr: 2px solid;   

// Button Customization
$ButtonbgColor: $SecondaryColor;
$ButtonPadding: 7px 22px;
$ButtonRadius: 12px;
$ButtonHoverColor: $SecondaryColor;
$TextTransform: uppercase ;
$ButtonWeight: 600 ;
$BorderType: none ;

// Button Font Size Desktop
$SmallerButtonFont: 16px;
$SmallButtonFont: 18px;
$MediumButtonFont: 18px;
// $LargeButtonFont: 26px;
// $LargerButtonFont: 30px;

// Button Font Size Mobile
$SmallerButtonFontMobile: 14px;
$SmallButtonFontMobile: 10px;
$MediumButtonFontMobile: 16px;

// Button Font Size Tablet
$SmallButtonFontTablet: 14px;
$MediumButtonFontTablet: 16px;

// Navbar Menu
$NavbarFontSizeDesktop:18px;
// $NavbarFontSizeMobile:14px;
// $NavbarFontSizeTablet:12px;
$NavbarFontWeight: 400 ;

// Footer Text Size
$FooterHeading: 25px ;
$FooterParagraph: 16px ;

